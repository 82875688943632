<template>
  <div class="fileWord">
    <van-nav-bar
      left-arrow
      @click-left="onClickLeft"
      :fixed="true"
      :placeholder="true"
      left-text="返回"
    />

    <div class="content" v-html="html"></div>
  </div>
</template>

<script>
import qs from "qs";
import { filePreview } from "@api/network.js";
export default {
  name: "fileword",
  data() {
    return {
      html: "",
      tabType:''
    };
  },
  methods: {
    onClickLeft() {
       this.$router.push({
         name:"Network",
         query:{
           tabType:this.tabType
         }
       })
    },
  },
  created() {
    let params = {
      deskInfoId: this.$route.query.id,
    };
    filePreview(qs.stringify(params))
    .then((res) => {
      if (res.code == 0) {
        this.html = res.msg ? res.msg : "";
      } else {
        this.$toast(res.msg);
      }
    });

    this.tabType = this.$route.query.tabType
  },
};
</script>

<style lang="scss" scoped>
::v-deep .van-nav-bar .van-icon {
  color: #333;
}
::v-deep .van-nav-bar__title {
  font-size: 17px;
  font-weight: bold;
  color: #333333;
}
::v-deep .van-nav-bar__text{
  color: #333;
}
.fileWord {
  .content {
    width: 100%;
    padding: 20px 20px 0;
    font-size: 14px !important;
    font-weight: 400;
  }
}
</style>